<template>
    <div class="menu_index" :class="$store.state.menuShow ? 'show' : ''">

        <!-- 菜单 -->
        <!-- <div class="menu_collapse" :class="$store.state.menuShow ? 'show' : ''"> -->
        <!-- <Fanfu-Menu-Item ref="FanfuMenuItem" v-for="(menu, index) in routes" :key="`menu_${index}`" :menu="menu" -->
        <!-- :index="index" :CloseMenuExcept="CloseMenuExcept" /> -->
        <!-- <Fanfu-Menu-Item v-for="(menu, index) in routes" :key="`menu_${index}`" :menu="menu" v-if="index!=6" /> -->
        <!-- </div> -->


        <div class="menu_collapse" :class="$store.state.menuShow ? 'show' : ''">
            <!-- <Fanfu-Menu-Item ref="FanfuMenuItem" v-for="(menu, index) in routes" :key="`menu_${index}`" :menu="menu"
                :index="index" :CloseMenuExcept="CloseMenuExcept" /> -->


            <el-menu class="elementMenu" router :collapse="!$store.state.menuShow" @open="handleOpen"
                @close="handleClose">

                <template v-for="(menu, index) in routes">

                    <el-submenu :index="menu.path" v-if="menu.children">
                        <template slot="title">
                            <!-- <i :class="menu.icon"></i> -->
                            <i class="layui-icon" :class="menu.icon"></i>

                            <span slot="title">{{menu.title}}</span>
                        </template>
                        <el-menu-item :index="menuSub.path" v-for="(menuSub, ind) in menu.children"
                            :key="`menuSub_${ind}`">{{menuSub.title}}</el-menu-item>
                    </el-submenu>

                    <el-menu-item :index="menu.path" v-else>
                        <!-- <i :class="menu.icon"></i> -->
                        <i class="layui-icon" :class="menu.icon"></i>

                        <span slot="title">{{menu.title}}</span>
                    </el-menu-item>

                </template>

            </el-menu>

        </div>



        <!-- <p class="el-footer1" :class="$store.state.menuShow ? '' : 'el-footer2'">技术支持 一联（天津）科技</p> -->


        <!-- 大标题 -->
        <div v-if="$store.state.menuShow" class="title">
            {{ $store.state.title }}
        </div>


        <div v-else class="logo">
            <!-- logo -->
            <img style="margin-top: 10px;" src="http://yifei-php-admin.tjjtln.com/static/admin/assets/images/favicon.ico" alt="">
        </div>

    </div>
</template>
<script>
    import {
        routes
    } from '@/router'






    export default {
        data() {
            return {
                routes,
                route: this.$route.path,
            }
        },

        computed: {
            // 菜单栏切换时关闭所有菜单子集 2023年5月20日17:47:06
            isMenuShow() {
                console.log("AAAAAAAAAAAAAAAAAAAAAA", this.routes);
                return this.$store.state.menuShow
            }
        },
        watch: {
            // 菜单栏切换时关闭所有菜单子集
            isMenuShow(newvalue, oldvalue) {
                this.CloseMenuExcept()
            },
            routes(value) {

            }
        },

        methods: {

            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            },





            // 关闭所有菜单子集
            CloseMenuExcept(val) {

                var array = this.$refs.FanfuMenuItem;

                if (array == true) {
                    for (let a = 0; a < array.length; a++) {
                        if (a != val) {
                            array[a].closeFun()
                        }
                    }
                }


            }

        },



    }
</script>
<style lang="scss" scoped>
    @import '@/assets/var.scss';

    .menu_index {
        height: 100%;
        background: #fff;
        transition: width 0.3s;
        box-shadow: $--layout-shadow;
        width: 64px;
        position: relative;
        z-index: 1000;

        overflow-x: hidden;

        // padding-top: 70px;


        // border: 2px purple dotted;
        // box-sizing: border-box;




        &.show {
            width: $--menu-width;
        }

        .title {
            font-size: 18px;
            text-align: center;
            height: 69px;
            line-height: 69px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            font-weight: bold;
            color: #6A83FC;

            // width: calc($--menu-width - 0px);

            // border: 2px purple dotted;
            // box-sizing: border-box;

            position: fixed;
            top: 0;
            left: 0px;
            background-color: #fff;
        }

        .logo {
            font-size: 18px;
            text-align: center;
            height: 69px;
            line-height: 69px;

            width: 64px;

            // border: 2px green dotted;
            // box-sizing: border-box;
            position: fixed;
            top: 0;
            left: 0px;
            background-color: #fff;
        }

        .menu_collapse {
            width: 64px;
            transition: width 0.3s;

            // margin-left: 5px;


            position: relative;
            left: 47%;
            transform: translateX(-50%);

            margin-top: 70px;

            // margin-left: 1px;
            // margin: 0 auto;

            // border: 2px red solid;
            // box-sizing: border-box;

            // max-height: calc(100vh - 70px);
            // height: calc(100vh - 70px);

            // overflow: scroll;


            &.show {
                width: $--menu-width;

                // border: 2px yellow solid;
                // box-sizing: border-box;
            }

            // border: 2px red solid;
            // box-sizing: border-box;
        }



    }



    .el-footer1 {
        // display: flex;
        // justify-content: center;
        // align-content: center;
        // align-items: center;

        // border: 1px red dotted;
        box-sizing: border-box;

        // height: 100%;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #576193;
        // line-height: 56px;
        // height: 56px;
        // white-space: wrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 1;

        // transform: scale(0.8);

        text-align: center;
        padding: 5px;
    }

    .el-footer2 {
        display: none;
    }






    // 菜单  

    .elementMenu {
        // border: 1px red dotted;
        // box-sizing: border-box;
        // width: 200px;
        width: 90%;
        margin: 0 auto;

        // padding: 10px;
        .el-menu-item,
        .el-submenu {
            // border: 1px red dotted;
            // box-sizing: border-box;
            // width: 220px;
            margin: 0 auto;
            // margin-left: 0px;



        }

        // .el-menu-item:hover,
        // // .el-submenu:hover 
        // .el-submenu__title:hover {
        //     // border: 1px red dotted;
        //     // box-sizing: border-box;
        //     // width: 220px;
        //     // margin: 0 auto;
        //     // margin-left: 0px;

        //     // background: #F6F6F6;
        //     // background: #f00;
        //     box-shadow: 4px 1px 8px 0px rgba(162, 162, 162, 0.56);
        //     border-radius: 10px;
        // }

    }




    /deep/.el-tooltip {
        // border: 1px greenyellow solid;
        // box-sizing: border-box;
        padding: 0px !important;
        text-align: center;
    }



    /deep/.el-submenu__title {
        // border: 1px greenyellow solid;
        // box-sizing: border-box;
        padding: 0px !important;
        text-align: center;
    }

    .show {

        /deep/.el-submenu__title {
            // border: 1px red solid !important;
            // box-sizing: border-box;
            padding: 0 20px !important;
            text-align: left;
        }
    }

    .el-menu-item.is-active {
        // color: #768dfc;
        background-color: #768dfc;
        color: #fff;
        border-radius: 10px;
    }
</style>